<template>
  <div id="meetingOrder">
    <h2 class="meeting-title">会议室订单</h2>
    <!-- 表格 -->
    <el-table :data="meetingData" style="width: 100%">
      <el-table-column align="center" prop="space.name" label="会议室名称"></el-table-column>
      <el-table-column align="center" label="预定时间">
        <template slot-scope="scope">
          <span>{{scope.row.created_at | timeFilter}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" label="使用时间">
        <template slot-scope="scope">
          <div><span style="margin-right: 5px">{{scope.row.start_time | userTimeFilter}}</span>至</div>
          <div><span>{{scope.row.end_time | userTimeFilter}}</span></div>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="user.nickname" label="预订人">
        <template slot-scope="scope">
          <span v-if="scope.row.user.name">{{scope.row.user.name}}</span>
          <span v-else>{{scope.row.user.nickname}}</span>
        </template>
      </el-table-column>
      <el-table-column align="center" prop="user.mobile" label="预定人联系方式"></el-table-column>
      <el-table-column align="center" label="订单金额(元)" width="155">
        <template slot-scope="scope">
          <el-input-number v-model="scope.row.price" controls-position="right" :precision="2" :min="0" :max="999999" placeholder="请输入金额" style="width:100%" :disabled="scope.row.status == 1" @change="moneyChange(scope.row)"></el-input-number>
        </template>
      </el-table-column>
      <el-table-column align="center" label="状态">
        <template slot-scope="scope">
          <el-select v-model="scope.row.status" placeholder="请选择" @change="((sta) => {statusChange(sta,scope.row)})" :disabled="scope.row.status == 1">
            <el-option v-for="(item,index) in statusArray" :value="item.statu" :label="item.name" :key="index"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="center" label="支付方式">
        <template slot-scope="scope">
          <el-select v-model="scope.row.pay_type" placeholder="请选择" @change="((sta) => {payChange(sta,scope.row)})" :disabled="scope.row.pay_time != null">
            <el-option v-for="(item,index) in payArray" :value="item.statu" :label="item.name" :key="index" :disabled="item.disabled"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column align="center" label="支付时间">
        <template slot-scope="scope">
          <span>{{scope.row.pay_time}}</span>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页组件 -->
    <Page
      @currentPageChange="currentPageChange"
      :pageSize="pageData.per_page"
      :page="pageData.current_page"
      :total="pageData.total"
      style="text-align:center;margin-top:10px"
    ></Page>
    <!-- 弹出款 -->
    <el-dialog title="线下支付时间设置" :visible.sync="payTimeSet" width="30%" :show-close="false">
      <el-form :model="payTimeForm">
        <el-date-picker
          value-format="yyyy-MM-dd HH:mm:ss"
          v-model="payTimeForm.pay_time"
          type="datetime"
          placeholder="选择日期时间">
        </el-date-picker>
      </el-form>
      <div slot="footer" class="dialog-footer">      
        <el-button @click="payTimeSet = false;getMedia()">取 消</el-button>
        <el-button type="primary" @click="payTimeSet = false;payTiemUp()">确 定</el-button>
      </div>        
    </el-dialog>    
  </div>
</template>
<script>
import Page from "@/components/PageComponents";
import { error, success } from "@/utils/notification";

export default {
  name: "meetingOrder",
  data() {
    return {
      id: Number(sessionStorage.organizationId),
      meetingData: [],
      page: 1,
      statusArray: [
        {statu: 0, name: '待审核'},
        {statu: 1, name: '通过'},
        {statu: 2, name: '拒绝'}
      ],
      payArray: [
        {statu: 0, name: '未支付'},
        {statu: 1, name: '线上支付', disabled: true},
        {statu: 2, name: '线下支付'}
      ],
      pageData: {
        //分页数据
        per_page: 0,
        current_page: 0,
        total: 0
      },
      payTimeSet: false,
      payTimeForm:{
        pay_time: ''
      },
      rID: ''
    }
  },
  components: {
    Page
  },
  filters: {
    timeFilter: function(t) {
      return t.slice(0,10)
    },
    userTimeFilter: function(t) {
      return t.slice(0,16)
    }
  },
  methods: {
    // 分页切换
    currentPageChange(p) {
      this.page = p;
      this.getMedia();
    },
    // 金额修改
    moneyChange(row){
      this.$http
        .put('/api/organs/'+ this.id +'/meeting_room/set/'+ row.id +'/price', {
          price: row.price*100
        }).then(res => {
          success ('更改成功')
          this.getMedia();
        });      
    },    
    // 状态
    statusChange(sta,row) {
      let upStatus = false
      if(sta == 1){
        if(row.price == 0){
          error('请填写金额')
        }else{
          upStatus = true
        }
      }else{
          upStatus = true
      }
      if(upStatus){
        this.$http
          .put('/api/organs/'+ this.id +'/meeting_room/check/'+ row.id +'/status', {
            status: sta
          }).then(res => {
            success ('更改成功')
            this.getMedia();
          }).catch(function (error) {
            this.getMedia();
        })
      }
    },
    // 支付方式改变
    payChange(sta, row) {
      if(sta == 2) {
        this.payTimeSet = true
      }
      this.rID = row.id
      console.log(this.rID)
    },
    // 提交时间
    payTiemUp(){
      this.$http
        .put('/api/organs/' + this.id + '/meeting_room/set/'+ this.rID +'/pay_type', {
          pay_type: 2
        }).then(res => {
          this.$http
            .put('/api/organs/' + this.id + '/meeting_room/set/' + this.rID + '/pay_time', {
              pay_time: this.payTimeForm.pay_time
            }).then(res => {
              success ('更改成功')
              this.getMedia();
            });
        });
    },
    // 获取团队服务列表
    getMedia() {
      this.$http
        .get('/api/organs/'+ this.id +'/space/meeting_room/record', {
          params: {
            page: this.page
          }
        }).then(res => {
          let d = res.data.data.data;
          d.map((item)=>{
            if(item.price === '' || item.price === undefined){
              item.price = 0
            }
            if(item.price !== '' && item.price !== undefined){
              item.price = item.price/100
            }
            if(item.pay_type === '' || item.pay_type === undefined){
              item.pay_type = 0
            }
          })
          this.meetingData = d
          this.pageData = res.data.data.meta;
        });
    }
  },
  created() {
    this.getMedia();
    setInterval(() => {
      this.getMedia();
    }, 15000);
  }
}
</script>
<style lang="less" scoped>
  .el-date-editor.el-input{
    width: 100%;
  }
  .meeting-title{
    margin-bottom: 20px;
  }
</style>
